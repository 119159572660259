
/**
 * Count number of decimals of a number
 * @param {Number} value The number to count
 * @returns {Number} the number of fraction digits
 */
 function countDecimals(value) {
  if (Math.floor(value) !== value)
    return value.toString().split(".")[1].length || 0;
  return 0;
}

// Updates Number entities that have interval.
document.querySelectorAll("[value][data-interval]").forEach(number => {
  // Grabs the numeric value from `value` attribute.
  let value = parseFloat(number.getAttribute("value"));
  // Grabs the interval in seconds. This attribute exists here.
  const interval = parseInt(number.getAttribute("data-interval"));
  // Grabs the increment amount, default to 1 if not defined or NaN.
  const increment = parseFloat(number.getAttribute("data-increment")) || 1;
  // Grabs the last modified date, default to current time.
  const dateModified = new Date(number.getAttribute("data-dateModified"));
  const secondsElapsed = Math.round((new Date() - dateModified) / 1000);

  const minimumFractionDigits = countDecimals(increment);

  // Adjusts the value to the current time.
  value += Math.round(secondsElapsed / interval) * increment;
  number.innerText = value.toLocaleString(undefined, {
    minimumFractionDigits,
  });

  setInterval(function() {
    value += increment;
    number.innerText = value.toLocaleString(undefined, {
      minimumFractionDigits,
    });
  }, interval * 1000);
});
