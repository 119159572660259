const API_KEY = "AIzaSyA-Fs_O-hEkMyoUeVg0CTwcQF2ecY-gTn8";
const SHEETID = "1yzLUjGeNV_1K5PYEuGFQsqLeofvB90_j8AxUG1-qpAM";
const WORKSHEET = "2";

// https://spreadsheets.google.com/feeds/list/1yzLUjGeNV_1K5PYEuGFQsqLeofvB90_j8AxUG1-qpAM/1/public/full?orderby=column:timestamp&reverse=true&alt=json

if (document.querySelector(".wait-time")) {
  // fetch(`https://spreadsheets.google.com/feeds/list/${SHEETID}/${WORKSHEET}/public/full?alt=json`, {
  fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SHEETID}/values/Results/?key=${API_KEY}`, {
    headers: {
      "Content-Type": "application/json"
    },
  })
  .then(response => response.json())
  .then(data => {
    data.values.forEach(function(entry) {
      const location = entry[0],
          waittime = entry[1],
          timestamp = entry[2];

      if (waittime) {
        document.querySelectorAll('.wait-time[data-location="'+location+'"], .wait-time [data-location="'+location+'"]')
        .forEach(element => element.innerHTML = ` ${waittime} minutes`);
      }
    });
  });
}
