// Import "globals" instead of "jquery" or "riot" so we could have them in global `window`.
import "./globals.js";

import "./counter.js";
import "./apis.js";
import "./jquery.js";
import "./navs.js";

import "./map.js";
import mapStyles from "./map-styles.js";

import "./modals.js";

riot.mount("google-map", {
  styles: mapStyles,
});
