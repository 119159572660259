import $ from "../../node_modules/cash-dom/dist/cash.esm.js";

$(function documentReadyHandler() {
  $(".form [formaction]").each(function() {
    const submitBtn = this;
    const formaction = submitBtn.getAttribute("formaction");
    const formmethod = submitBtn.getAttribute("formmethod") || "GET";
    const formtarget = submitBtn.getAttribute("formtarget");
    const form = submitBtn.form;

    form.addEventListener("submit", function(event) {
      if (formtarget === "_blank") {
        event.preventDefault();

        const $form = $(form);

        fetch(formaction, {
          method: formmethod.toUpperCase(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // Set this header so request is detected as AJAX in backend.
            "X-Requested-With": "XMLHttpRequest",
          },
          body: $form.serialize(),
        })
        .then(response => response.text())
        .then(message => {
          $form.html(message);
        });
      } else {
        return true;
      }
    });
  });

  // Sort articles and events.
  $(`[itemtype*="ItemList"]`).each(function() {
    const $list = $(this);

    if (!$list.children(`[itemtype*="Article"], [itemtype*="Event"]`).length) {
      return;
    }

    const $articles = $list.find(`[itemtype*="Article"]`);
    const $events = $list.find(`[itemtype*="Event"]`);

    Array.from($articles)
    .sort(function(a, b) {
      a = Date.parse($(`[itemprop="datePublished"]`, a).attr("datetime"));
      b = Date.parse($(`[itemprop="datePublished"]`, b).attr("datetime"));
      return b - a;
    })
    .forEach(function(element) {
      $(element).appendTo($list)
    });

    Array.from($events)
    .sort(function(a, b) {
      a = Date.parse($(`[itemprop="startDate"]`, a).attr("datetime"));
      b = Date.parse($(`[itemprop="startDate"]`, b).attr("datetime"));
      return a - b;
    }).forEach(function(element) {
      $(element).appendTo($list)
    });;
  });

  function filter($items, name, value) {
    let $matches;
    if (value) {
      const values = value.split(/;\s?/g);

      $matches = $items
      .addClass("hidden")
      .filter(function(index, element) {
        const attribute = element.getAttribute(`data-${name}`);
        // The attribute value can be a list separated by ",", i.e multiple
        // team a person belongs to.
        // Here we use `.some` instead of `.every` because we want to filter on
        // items matching either of the `values`, not ALL.
        // For example, the Article can have "Article;Podcast" filter to match
        // either Article type or Podcast type.
        if (values.some(value => attribute.indexOf(value) > -1)) {
          return true;
        }

        if (attribute !== "*") {
          return false;
        }

        if (values.find(element => element.innerText.indexOf(value) > -1)) {
          return true;
        }

        return false;
      })
      .removeClass("hidden");
    } else {
      $matches = $items.removeClass("hidden");
    }

    const $container = $items.parent();
    const $moreButton = $container.next(".button");
    if (!$moreButton.length) return;

    let $invisibles = $matches.show().slice(5).hide();
    $moreButton.toggle($invisibles.length > 0);

    $moreButton.off("click.more");
    $moreButton.on("click.more", function() {
      $invisibles = $invisibles.show().slice(5).hide();
      $moreButton.toggle($invisibles.length > 0);
    });

  }

  $(window).on("load", function() {

    $(".filter").each(function() {
      var $filter = $(this),
          name = $filter.prop("name"),
          target = $filter.attr("data-target") || document,
          $target = $(target),
          $items = $("[data-"+name+"]", $target);

      var value;
      if ((this.type !== "radio" && this.type !== "checkbox") || this.checked) {
        value = this.value;
      }

      if (value) {
        filter($items, name, value);
      }

      $filter.on("change", function() {
        var value;
        if ((this.type !== "radio" && this.type !== "checkbox") || this.checked) {
          value = this.value;
        }

        filter($items, name, value);
      });
    });

    function prepend(option, $select, $options = $select.options) {
      var $option = $options.find(function($option) {
        return $option.innerText.trim() === option;
      });
      if ($option) {
        $select.prepend($option);
      }
    }

    // Special sorting for Team filter on Leadership page.
    // Refs: ticket 13742.
    $(".filter.team").each(function() {
      var $filter = $(this), $options = Array.from($filter.find("option"));
      // Sorts the options alphabetically first.
      $options.sort(function(a, b) {
        return a.innerText > b.innerText ? 1 : a.innerText < b.innerText ? -1 : 0;
      });
      $options.forEach(function($option) {
        $filter.append($option);
      });
      // Then chery picks a few options to be first.
      prepend("Milestone Carbon", $filter, $options);
      prepend("Milestone Environmental Services", $filter, $options);
      prepend("Leadership Team", $filter, $options);
      prepend("All", $filter, $options);
    });

  });

  $("form").has(".filter").on("submit", function(event) {
    event.preventDefault();
  });
});
