import $ from "../../node_modules/cash-dom/dist/cash.esm.js";

$(function documentReadyHandler() {

  const $document = $(document);

  /**
   * Toggles a variant on an element.
   * @param {HTMLElement} navItem
   * @param {boolean} force whether to force open
   * @param {string} variant the variant to toggle.
   */
  function toggle(navItem, force, variant = "hover:") {
    const classList = navItem.classList;
    classList.forEach(function(className) {
      if (className.indexOf(variant) > -1) {
        classList.toggle(className.replace(variant, ""), force);
      }
    });

    const hoverables = navItem.querySelectorAll(`[class*="group-hover:"]`);
    Array.from(hoverables).forEach(function(item) {
      toggle(item, force, `group-${variant}`);
    });
  }

  //#region Primary nav
  $document.on("mouseover", `nav[aria-label="Primary"] .isParent`, function(event) {
    toggle(this, true);
  });

  $document.on("mouseout", `nav[aria-label="Primary"] .isParent`, function(event) {
    toggle(this, false);
  });

  $document.on("click", `nav[aria-label="Primary"] .isParent > button`, function(event) {
    const target = this;
    const navItem = target.closest(".isParent");
    $(navItem).siblings(function(_, sibling) {
      toggle(sibling, false);
      const button = $(sibling).children("button")[0];
      button && button.setAttribute("aria-expanded", "false");
    });

    toggle(navItem);
    if (target.getAttribute("aria-expanded") === "true") {
      target.setAttribute("aria-expanded", "false");
    } else {
      target.setAttribute("aria-expanded", "true");
    }
  });

  $document.on("click", `[aria-controls="menu"]`, function(event) {
    const button = this;
    const state = button.getAttribute("aria-expanded");
    const isOpen = state === "true";
    button.setAttribute("aria-expanded", isOpen ? "false" : "true");
    button.innerText = isOpen ? "Menu" : "Close";
    button.classList.toggle("siblings:hidden");

    // Prevents scolling past the menu.
    document.body.classList.toggle("overflow-hidden", !isOpen);
  });
  //#endregion

});
